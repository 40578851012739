import * as React from 'react'
import { submissionService } from '@oneblink/apps'
import { CalendarBookingsForm } from '@oneblink/apps-react'
import { useHistory } from 'react-router-dom'

function SchedulingFormScene() {
  const history = useHistory()

  const handleDone = React.useCallback(
    async (formSubmissionResult: submissionService.FormSubmissionResult) => {
      await submissionService.executePostSubmissionAction(
        {
          ...formSubmissionResult,
          scheduling: null,
        },
        {
          onRedirectToRelativeUrl: history.replace,
          onRedirectToAbsoluteUrl: (url) => window.location.replace(url),
        },
      )
    },
    [history.replace],
  )

  return <CalendarBookingsForm onDone={handleDone} />
}

export default React.memo(SchedulingFormScene)
