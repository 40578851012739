import * as React from 'react'
import { Snackbar, Button } from '@mui/material'

function RefreshSnackbar({
  isOpen,
  onClick,
  message,
}: {
  isOpen: boolean
  onClick: () => void
  message: string
}) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={isOpen}
      message={message}
      action={[
        <Button
          key="refresh"
          size="small"
          color="inherit"
          variant="text"
          onClick={onClick}
        >
          Refresh
        </Button>,
      ]}
    />
  )
}

export default React.memo(RefreshSnackbar)
