import * as React from 'react'
import tenants from '@oneblink/apps/dist/tenants'
import {
  Workbox,
  WorkboxLifecycleEvent,
  WorkboxLifecycleWaitingEvent,
} from 'workbox-window'
import utilsService from '@oneblink/apps/dist/services/utils'
import { FormsAppsTypes } from '@oneblink/types'

import RefreshSnackbar from 'components/RefreshSnackbar'
import useServiceWorkerMessageEffect from './useServiceWorkerMessageEffect'

const cachingStrategies = window.formsHostnameConfiguration?.cachingStrategies

const FORMS_APP_CONFIG_KEY = 'FORMS_APP_CACHING_STRATEGIES'

type SWNeedRefresh = 'RELOAD_BROWSER' | 'SEND_SKIP_WAITING' | null

const NewContentSnackbarContext = React.createContext<{
  swNeedRefresh: SWNeedRefresh
}>({ swNeedRefresh: null })

export default function NewContentSnackbar({
  children,
}: {
  children: React.ReactNode
}) {
  const [swNeedRefresh, setSwNeedRefresh] = React.useState<SWNeedRefresh>(null)

  const wb = React.useMemo(() => {
    const params = new URLSearchParams()

    if (window.formsHostnameConfiguration?.cachingStrategies) {
      params.append(
        'cachingStrategies',
        JSON.stringify(window.formsHostnameConfiguration?.cachingStrategies),
      )
    }
    params.append('apiOrigin', tenants.current.apiOrigin)

    return new Workbox(`/sw.js?${params.toString()}`)
  }, [])

  React.useEffect(() => {
    // Do not attempt to register a service worker during development
    if (!import.meta.env.PROD) {
      return
    }

    const waitingListener = (event: WorkboxLifecycleWaitingEvent) => {
      console.log('Service worker is waiting', event)
      if (cachingStrategies?.app === 'STALE_WHILE_REVALIDATE') {
        setSwNeedRefresh('SEND_SKIP_WAITING')
      }
    }
    wb.addEventListener('waiting', waitingListener)

    const controllingListener = (event: WorkboxLifecycleEvent) => {
      console.log('Service worker is controlling this client', event)
      if (
        cachingStrategies?.app === 'STALE_WHILE_REVALIDATE' &&
        event.isUpdate
      ) {
        window.location.reload()
      }
    }
    wb.addEventListener('controlling', controllingListener)

    const register = async () => {
      try {
        const [serviceWorkerRegistration, storedConfig] = await Promise.all([
          navigator.serviceWorker.ready,
          utilsService.getLocalForageItem<{
            value: FormsAppsTypes.FormsAppConfiguration['cachingStrategies']
          }>(FORMS_APP_CONFIG_KEY),
          wb.register(),
        ])

        console.log('Comparing caching strategies', {
          previous: storedConfig?.value,
          new: window.formsHostnameConfiguration?.cachingStrategies,
        })
        if (
          storedConfig &&
          JSON.stringify(storedConfig.value) !==
            JSON.stringify(window.formsHostnameConfiguration?.cachingStrategies)
        ) {
          // We purge the cache entries before unregistering the service worker so that
          // when the app reloads, we have a brand new service worker and no old
          // cache that will prevent the app from getting the latest content on the
          // first load. We don't need to wait for the message to be processed before
          // unregistering as the service worker will complete all in progress actions first
          // https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration/unregister
          console.log(
            'Purging service worker cache due to change in caching strategies',
            {
              previous: storedConfig.value,
              new: window.formsHostnameConfiguration?.cachingStrategies,
            },
          )
          wb.messageSW({
            type: 'PURGE_CACHE',
          })

          console.log(
            'Unregistering service worker due to change in caching strategies',
            {
              previous: storedConfig.value,
              new: window.formsHostnameConfiguration?.cachingStrategies,
            },
          )
          await serviceWorkerRegistration.unregister()

          setSwNeedRefresh((current) => {
            if (current) {
              return current
            }
            return 'RELOAD_BROWSER'
          })
        }

        await utilsService.setLocalForageItem(FORMS_APP_CONFIG_KEY, {
          value: window.formsHostnameConfiguration?.cachingStrategies,
        })
      } catch (error) {
        console.warn(
          'An error occurred while registering the service worker.',
          error,
        )
      }
    }
    register()

    return () => {
      wb.removeEventListener('waiting', waitingListener)
      wb.removeEventListener('controlling', controllingListener)
    }
  }, [wb])

  const handleClick = React.useCallback(() => {
    switch (swNeedRefresh) {
      case 'SEND_SKIP_WAITING': {
        wb.messageSkipWaiting()
        break
      }
      case 'RELOAD_BROWSER':
      default: {
        window.location.reload()
        break
      }
    }
  }, [swNeedRefresh, wb])

  useServiceWorkerMessageEffect(
    React.useCallback((payload) => {
      if (payload.contentToRefresh === 'app') {
        setSwNeedRefresh((current) => {
          if (current) {
            return current
          }
          return 'RELOAD_BROWSER'
        })
      }
    }, []),
  )

  const value = React.useMemo(
    () => ({
      swNeedRefresh,
    }),
    [swNeedRefresh],
  )

  return (
    <NewContentSnackbarContext.Provider value={value}>
      <RefreshSnackbar
        message="New content available, please refresh."
        isOpen={!!swNeedRefresh}
        onClick={handleClick}
      />
      {children}
    </NewContentSnackbarContext.Provider>
  )
}

export function useGlobalNewContentSnackbarState() {
  return React.useContext(NewContentSnackbarContext)
}
